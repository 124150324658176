<template>
  <layout-stepper :key="current_step">
    <template #body>
      <div class="d-flex">
        <b-col class="col-6 text-start">
          <b-link @click="makeRedirect()" v-if="has_prev_step" class="link-back">
            <feather-icon icon="ChevronLeftIcon"></feather-icon>
            {{ $t('creator.previousStep') }}
          </b-link>
        </b-col>
      </div>
      <div>
        <category-subcategory v-if="current_step === 'category'"/>
        <connection v-if="current_step === 'connection'"/>
        <deals v-if="current_step === 'deals'"/>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BLink,
  BCol,
} from 'bootstrap-vue';

export default {
  name: 'settingProposalDashboard',
  components: {
    BLink,
    BCol,
    layoutStepper: () => import('@/views/components/proposals/layoutStepper.vue'),
    connection: () => import('./enrichmentConnection.vue'),
    deals: () => import('./deals.vue'),
    categorySubcategory: () => import('./categorySubcategory.vue')
  },
  data() {
    return {
      has_prev_step: false,
      current_step: this.$route.params.step,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },
  methods: {
    makeRedirect() {
      if (this.current_step === 'connection') this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
      if (this.current_step === 'deals') this.$router.push({ name: 'steps_proposal', params: {step: 'connection'}})
    },
  },
  watch: {
    '$route.params.step'(new_step) {
      this.current_step = new_step
      if (new_step !== 'category') this.has_prev_step = true
      else this.has_prev_step = false
    }
  }
}
</script>